import { Placeholder } from "rsuite";
import { Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css'

const OfferSwiper = () => {
  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={24}
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
        >
          <SwiperSlide>
            <div className='sw1-card-header'>
              <img src={require('../../images/offer1.png')} alt='Предложение 1' width={340} height={100} style={{top: 20}}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sw1-card-header'>
              <img src={require('../../images/offer2.png')} alt='Предложение 2' width={340} height={100} style={{top: 20}}/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='sw1-card-header'>
              <img src={require('../../images/offer3.png')} alt='Предложение 3' width={340} height={100} style={{top: 20}}/>
            </div>
          </SwiperSlide>
      </Swiper>
    </>
  );
}

export default OfferSwiper;