import { Tabs, Button } from "rsuite";
import { useNavigate } from 'react-router-dom';
const BackButton = window.Telegram.WebApp.BackButton;


const FeedBack = () => {
  const navigate = useNavigate();

  BackButton.isVisible = true;
  BackButton.onClick(() => window.history.go(-1))

  const PopupCallback = (button_id) => {
    console.log(1)
    if (button_id === '0'){navigate('/purchases/')}
  };

  function handleButton(){
    window.Telegram.WebApp.showPopup({
      title: 'Оставить отзыв',
      message: 'Чтобы иметь возможность оценить нашу работу, вы совершить хотя бы одну покупку. Перейти в раздел покупки?',
      buttons: [
        {id: 0, type: 'ok', text: 'Перейти'},
        {id: 1, type: 'destructive', text: 'Отмена'}
      ]
    },PopupCallback)
  }

  return (
    <div className='main_container' style={{height: '100%'}}>
    <div style={{padding: 12}}>
      <div className='header' style={{display: 'flex'}}>
        <div style={{width: '50%'}}>Отзывы</div>
        <div style={{textAlign: 'right', width: '50%'}}>
          <Button appearance="primary" color='blue' onClick={() =>handleButton()}>Оставить отзыв</Button>
        </div>
      </div>

      <div style={{background: 'var(--secondary-bg-color)', borderRadius: 8, textAlign: 'left', padding: 8, height: 600}}>
        <Tabs defaultActiveKey="1">
          <Tabs.Tab eventKey="1" title="Все отзывы">
            <div style={{padding: 8}}>
              Раздел в разработке...
            </div>
          </Tabs.Tab>
          {/* <Tabs.Tab eventKey="2" title="Пересдачи">
            <div style={{padding: 8}}>
              Здесь пока нет отзывов...
            </div>
          </Tabs.Tab>
          <Tabs.Tab eventKey="3" title="Готовы задания">
            <div style={{padding: 8}}>
              Здесь пока нет отзывов...
            </div>
          </Tabs.Tab> */}
        </Tabs>
      </div>
    </div>
    </div>
  );
}

export default FeedBack
