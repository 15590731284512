import { useLocation } from "react-router-dom";
import { IconButton, Button} from "rsuite";
import { useState } from "react";
import CopyIcon from '@rsuite/icons/Copy';
import axios from "axios";


const tg = window.Telegram.WebApp;
const BackButton = window.Telegram.WebApp.BackButton;

const TopUpBalance = () => {
  const location = useLocation();
  const amount = location.state.amount;
  const fee_amount = (amount*1.05).toFixed(0);
  const bank = location.state.bank;
  const type = location.state.type;
  const [payed, setPayed] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  function RegisterPayment(){
    setDisabled(true);
    setLoading(true);
    axios({
      method: "POST",
      url:"https://3166783-sf57923.twc1.net/api/register_payment/",
      headers:{
        'Accept': 'application/json'
      },
      data: {
        'user_id': tg.initDataUnsafe?.user?.id,
        'amount': amount,
        'fee_amount': fee_amount,
        'type': type,
        'bank': bank,
        'user_tag': tg.initDataUnsafe?.user?.username

      }
    })
    .then((response) => {
      const data = response.data
      setPayed(true);
      console.log(data)
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response)
        }
    })
  };

  function Payed(){
    BackButton.onClick(null);
    window.location.replace('/')
  }
  const RenderNotPayed = () => {
    return (
      <>
      <div className='main_container' style={{height: '100%', padding: 16}}>
        <div style={{height: 160, background: 'var(--secondary-bg-color)', borderRadius: '20px 20px 20px 20px', padding: 12, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{fontSize: 48, color:'#3dadf2', fontWeight: 600}}>{type === 'СБП' ? fee_amount: amount} ₽</div>
        </div>
        {type === 'СБП' ?
          <div style={{height: 120, background: 'var(--secondary-bg-color)', borderRadius: '20px 20px 20px 20px', padding: 12, marginTop: 16}}>
            <div style={{fontSize: 16, marginTop: 12}}>По номеру:</div>
            <div style={{marginTop: 4}}>
                <IconButton icon={<CopyIcon />} placement='right' appearance="primary" color='blue' block
                  onClick={() => navigator.clipboard.writeText('+79501171775')}>
                +7 950 117 1775
                </IconButton>
            </div>
          </div>
        :
          <>
          </>
        }
        

        {type === 'СБП' ? 
          <div style={{textAlign: 'left', marginTop: 12, color: 'gray'}}>
            <div style={{fontWeight: 600}}>Инструкция СБП</div>
            <div>
              Перевести на указанный выше номер {fee_amount}₽.
            </div>
            <div>
            Среднее время пополнения: 5-7 минут
            </div>

            <div style={{marginTop: 12}}>
              Вы получите: {amount}₽
            </div>
            <div style={{marginTop: 4}}>
              Банк оплаты: {bank}
            </div>
            <div style={{marginTop: 4}}>
              Тип оплаты: {type}
            </div>
          </div>
        :
          <div style={{textAlign: 'left', marginTop: 12, color: 'gray'}}>
            <div style={{fontWeight: 600}}>Инструкция P2P</div>
            <div>
            <span>После нажатия на кнопку “Подтверждаю” с Вами свяжется менеджер с подтверждением готовности перевести деньги на реквизиты в течение 15 минут. После перевода от Вас понадобится чек, без него пополнение НЕ будет подтверждено. Среднее время пополнения: 10-15 минут</span>
            </div>

            <div style={{marginTop: 12}}>
              Вы получите: {amount}₽
            </div>
            <div style={{marginTop: 4}}>
              Банк оплаты: {bank}
            </div>
            <div style={{marginTop: 4}}>
              Тип оплаты: {type}
            </div>
          </div>
          }
        
      </div>

      {
        type === 'СБП' ?
        <div style={{width:'100%', position: 'fixed', bottom:0, padding: '0px 8px 8px 8px'}}>
          <Button loading={loading} disabled={disabled} appearance="primary" color="green" block size='lg' onClick={() => RegisterPayment()}>Я оплатил ({fee_amount}₽)</Button>
        </div>
        :
        <div style={{width:'100%', position: 'fixed', bottom:0, padding: '0px 8px 8px 8px'}}>
          <Button loading={loading} disabled={disabled} appearance="primary" color="green" block size='lg' onClick={() => RegisterPayment()}>Подтверждаю ({amount}₽)</Button>
        </div>
      }
      
      </>
  );
  }

  return (
    <>
    {(payed === true) ?
      Payed()
      
      :
      <RenderNotPayed />
    }
    </>
  );
}

export default TopUpBalance;