import { Placeholder } from "rsuite";
import { FlexboxGrid } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { useState , useEffect} from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.css'
import * as Chat from '@fortawesome/free-solid-svg-icons/faComments';
import * as List from '@fortawesome/free-solid-svg-icons/faRectangleList';
import * as Star from '@fortawesome/free-solid-svg-icons/faStar';
import * as Purchases from '@fortawesome/free-solid-svg-icons/faBasketShopping';
import TimeIcon from '@rsuite/icons/Time';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import GridIcon from '@rsuite/icons/Grid';

const tg = window.Telegram.WebApp;

const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
      <path d={svgPathData}></path>
    </svg>
  );
};



const ControlWidget = () => {
  const [referals, setReferals] = useState(0);
  const [reqLoading, setReqLoading] = useState(true);
  const [lastRequest, setLastRequest] = useState([]);
  const navigate = useNavigate();
  
  function getRequest(){
    axios({
      method: "POST",
      url:"https://3166783-sf57923.twc1.net/api/get_request/",
      headers:{
        'Accept': 'application/json'
      },
      data: {'user_id': tg.initDataUnsafe?.user?.id, 'count': 1}
    })
    .then((response) => {
      const data = response.data
      setLastRequest(data.requests);
      setReqLoading(false);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response)
        }
    })
  
  };
  
  useEffect(()=>{
    getRequest();
  }, []);

  const GetIcon = (props) => {
    let status = props.status;
    if(status === 'На рассмотрении'){
      return(<TimeIcon style={{fontSize: '3.2em'}}/>)
    }else if(status === 'Подтверждена'){
      return(<CheckOutlineIcon style={{fontSize: '3.2em', color: '#2af318'}}/>)
    }else{
      return(<CloseOutlineIcon style={{fontSize: '3.2em', color: 'red'}}/>)
    }
  }

  const RenderLR = () => {
    if(lastRequest.length === 0){
      return(
      <>
        <div style={{textAlign: 'left', padding: 4, display: 'flex'}}>
          <GridIcon style={{fontSize: '3.2em'}}/>
          <div style={{marginLeft: 12}}>Нет заявок</div>
        </div>
      </>
      )
    }
    else{
      return(
      <>
      <div style={{textAlign: 'left', padding: 4, display: 'flex'}}>
        <GetIcon status={lastRequest[0].status}/>
        <div style={{marginLeft: 12}}>Заявка на пополнение</div>
      </div>
      <div style={{textAlign: 'left', marginTop: 8, marginLeft: 2, color: '#c2c1c1', fontSize: 11}}>
        № {lastRequest[0].request_name}
        <div>
          Статус: {lastRequest[0].status}
        </div>
        <div>
          Сумма: {lastRequest[0].amount}₽
        </div>
      </div>
      </>

      )
    }
  }
  return (
    <div className="show-grid" style={{padding: 12}}>
    <FlexboxGrid justify="space-between">
      <FlexboxGrid.Item colspan={12}>
        <div className='onefour-big'>
          {reqLoading ? <Placeholder.Paragraph graph='circle' active rows={6} rowHeight={8} rowSpacing={10}/> :
          
          <RenderLR />
          
          }
          
        </div>
        </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={11}>
        <div className='onefour-sm-row'>
          <div className='onefour-sm' onClick={()=>window.location.replace('https://t.me/unknownanx')}>
            <div className='onefour-center'>
              <Icon as={FaSvgIcon} faIcon={Chat} style={{fontSize: '1.3em'}}/>
            </div>
            <span style={{fontSize: 12}}>Поддержка</span>
          </div>
          <div className='onefour-sm' onClick={() => navigate('/requests/')}>
            <div className='onefour-center'>
                <Icon as={FaSvgIcon} faIcon={List} style={{fontSize: '1.3em', color: '#029cb1'}}/>
            </div>
            <span style={{fontSize: 12}}>Заявки</span>
            </div>
        </div>
        <div className='onefour-sm-row' style={{marginTop: 4}} >
          <div className='onefour-sm' onClick={() => navigate('/feedback/')}>
            <div className='onefour-center'>
              <Icon as={FaSvgIcon} faIcon={Star} style={{fontSize: '1.3em', color: '#ffc757'}}/>
            </div>
            <span style={{fontSize: 12}}>Отзывы</span>
          </div>
          <div className='onefour-sm' onClick={() => navigate('/purchases/')}>
            <div className='onefour-center'>
              <Icon as={FaSvgIcon} faIcon={Purchases} style={{fontSize: '1.3em', color: '#7a7a7a'}}/>
            </div>
            <span style={{fontSize: 12}}>Покупки</span>
          </div>
        </div>
      </FlexboxGrid.Item>

    </FlexboxGrid>
  </div>
  );
}

export default ControlWidget;