import { BrowserRouter, Route, Routes} from 'react-router-dom';

import HomePage from './pages/home_page';
import FeedBack from './pages/feedback_page';
import BalancePage from './pages/balance_page';
import TopUpBalance from './pages/topup_balance';
import Requests from './pages/requests';
import Purchases from './pages/purchases';
import Contact from './components/Contact';
import './index.css';

import useAuth from './utils/useAuth';
function App() {
  const {user, number, loading} = useAuth();
  return (
    <>
      <BrowserRouter>
        {!user ? <div>Зайди с телеги</div> :(
          !number? <Contact loading={loading}/>:
        <Routes>
          <Route path='/' element={<HomePage/>}/>
          <Route path='/feedback/' element={<FeedBack/>}/>
          <Route path='/balance/' element={<BalancePage/>}/>
          <Route path='/top_up_balance/' element={<TopUpBalance/>}/>
          <Route path='/requests/' element={<Requests/>}/>
          <Route path='/purchases/' element={<Purchases/>}/>
        </Routes>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
