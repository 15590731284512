import { RadioTile, RadioTileGroup, useMediaQuery, SelectPicker } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { useState} from 'react';

import * as Transfer from '@fortawesome/free-solid-svg-icons/faWallet';
import SBPIcon from '../images/svg_icons';
import Keyboard from '../components/Keyboard/Keyboard';

const tg = window.Telegram.WebApp;
const BackButton = tg.BackButton;


const banks = ['Сбер', 'Т-Банк', 'ВТБ', 'Альфа-Банк', 'Другой'].map(
  item => ({ label: item, value: item })
);


const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
      <path d={svgPathData}></path>
    </svg>
  );
};


// function sendData(){
//   axios({
//     method: "POST",
//     url:"https://3166783-sf57923.twc1.net/api/register_payment/",
//     data: {
//       'user_id': user_id,
//       'amount': amount,
//       'fee_amount': (amount*1.05).toFixed(0),
//     },
//     headers:{
//       'Accept': 'application/json'
//     },
//   })
//   .then((response) => {
//     console.log('ok')
//   })
//   .catch((error) => {
//     if (error.response) {
//       console.log(error.response)
//       }
//   })
// };


const BalancePage = () => {
  const [transferType, setTransferType] = useState('СБП');
  const [isInline] = useMediaQuery('xs');
  
  BackButton.isVisible = true;

  const RenderBalanceTop = () => {
    const [amount, setAmount] = useState('1000');
    const [bank, setBank] = useState(banks[0].value);

    
    
    return (
      <>
      <div style={{marginLeft: 12, textAlign: 'left', marginTop: 0}}>
        <div style={{marginTop: 0}}>
          {transferType === 'СБП' ? 'Банк *' : 'Банк'}
        </div>
            <SelectPicker defaultValue={transferType === 'СБП' ? bank : 'Не требуется'} searchable={false} data={banks} disabled={transferType === 'P2P'} placeholder='Не требуется'
              style={{width:322, marginTop: 8}}
              size='md'
              cleanable={false}
              onSelect={v => setBank(v)}
            />
        
        {transferType === 'СБП' ?
        <> 
          <div style={{fontSize: 12, marginTop: 8}}>
            {amount < 500 ? 
              <span style={{color: '#f26a63'}}>Минимальная сумма 500₽</span>
            : 
              <span style={{color: 'gray'}}>Сумма с учетом комиссии: <b>{(amount*1.05).toFixed(0)}</b>₽</span>
            }
          </div>
          
        </>
        :
        <div style={{color: 'gray', fontSize: 12, marginTop: 8}}>
          {amount < 1000 ? 
              <span style={{color: '#f26a63'}}>Минимальная сумма 1000₽</span>
            : 
              <span style={{color: 'gray'}}>Сумма к оплате: <b>{amount}</b>₽</span>
            }
        </div> 
        }
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
          <Keyboard value={amount} setValue={e => setAmount(e)} transferType={transferType} bank={bank}/>
      </div>
      </>
    )
  };
  
  return (
    <>
    <div className='main_container' style={{height: '100%'}}>
      <div className='header' style={{marginTop: 12}}>
        Пополнение
      </div>
      <div style={{textAlign: 'left', padding: 12,fontSize: 12}}>
        <RadioTileGroup defaultValue={transferType} inline={isInline}>
          <RadioTile style={{width: '50%'}} 
            icon={<Icon as={SBPIcon}
            style={{width: '1em', height: '1em'}}/>}
            label='СБП'
            value='СБП'
            onChange={() => setTransferType('СБП')}
            >
            Перевод при помощи СБП на карту (+5%).
          </RadioTile>
          <RadioTile style={{width: '50%'}}
            icon={<Icon as={FaSvgIcon} 
              faIcon={Transfer} 
              style={{width: '1em', height: '1em'}}/>} 
            label='P2P'
            value='P2P'
            onChange={() => setTransferType('P2P')}
            >
            P2P перевод без комиссии по реквизитам.
          </RadioTile>
          
        </RadioTileGroup>
      </div>
      </div>
      <RenderBalanceTop/>
      </>
  );
}

export default BalancePage;