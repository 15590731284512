import axios from "axios";
import { useEffect, useState } from "react";
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import TimeIcon from '@rsuite/icons/Time';
import PageIcon from '@rsuite/icons/Page';
import { Loader } from "rsuite";


const BackButton = window.Telegram.WebApp.BackButton;
const tg = window.Telegram.WebApp;
const GetIcon = (props) => {
  let status = props.status;
  if(status === 'На рассмотрении'){
    return(<TimeIcon style={{fontSize: '2em'}}/>)
  }else if(status === 'Подтверждена'){
    return(<CheckOutlineIcon style={{fontSize: '2em', color: '#2af318'}}/>)
  }else{
    return(<CloseOutlineIcon style={{fontSize: '2em', color: 'red'}}/>)
  }
}

const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key].slice(0, 10)]: [
      ...(result[item[key].slice(0, 10)] || []),
      item,
    ],
  }), 
  {},
);

Object.entries = function(obj) {
  return Object.keys(obj).reduce(function(arr, key) {
      arr.push([key, obj[key]]);
      return arr;
  }, []);
}

const Requests = () => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);

  function getRequest(){
    axios({
      method: "POST",
      url:"https://3166783-sf57923.twc1.net/api/get_request/",
      headers:{
        'Accept': 'application/json'
      },
      data: {'user_id': tg.initDataUnsafe?.user?.id, 'count': 50}
    })
    .then((response) => {
      const data = response.data
      setRequests(data.requests);
      setLoading(false);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response)
        }
    })
  
  };
  
  useEffect(()=>{
    getRequest();
  }, []);

  BackButton.isVisible = true;
  BackButton.onClick(() => window.history.go(-1))


  function getStringDate(date) {
    const [day, month, year] = date.split('-')
    let this_date = new Date(year, month-1, day)
    let today = new Date().toLocaleDateString()
    let yesterday = new Date(new Date().setDate(new Date().getDate()-1)).toLocaleDateString()

    if(today === `${day}.${month}.${year}`){
      return 'Сегодня'
    }else if(yesterday === `${day}.${month}.${year}`){
      return 'Вчера'
    }else{
      let str_date = this_date.toLocaleDateString('ru-RU', {weekday: 'short', day: 'numeric', month: 'long'})
      return str_date[0].toUpperCase() + str_date.slice(1)
    }
  }


  const RenderRequests = () => {
    const result = groupBy(requests, 'created')
    const reqs = result

    return (
      <div>
        {Object.entries(reqs).map((item, index) => (
          <div key={index} style={{padding: 12}}>
            <div key={index} style={{fontSize: 20, textAlign: 'left', color: 'gray'}}>{getStringDate(item[0])}</div>
              <div style={{marginTop: 8}}>

                {item[1].map((i, ind) => (
                  <div key={ind} style={{background: 'var(--secondary-bg-color)', marginTop: 8, borderRadius: 20, textAlign: 'left', height: 120, padding: 12, display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{color: 'gray'}}>
                      <div style={{fontSize: 16, color: 'white'}}>Заявка на пополнение</div>
                      <div>№ {i.request_name}</div>
                      <div style={{fontSize: 12}}> Тип: {i.type} / {i.bank}</div>
                      <div style={{fontSize: 12}}> Время создания: {i.created.slice(10)}</div>
                      <div style={{fontSize: 12}}> Сумма к зачислению: {i.amount}₽</div>
                    </div>

                    <div style={{float: 'right', textAlign: 'right'}}>
                      <GetIcon status={i.status} />
                    </div>
                  </div>
                ))}

              </div>
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
    <div className='main_container' style={{height: '100%'}}>
      <div className='header'>
        Мои заявки
      </div>
      <div>
        
        {
          loading ?
          <div>
            <Loader size='lg' center vertical backdrop/>
          </div> :
          <></>
        }
        {requests.length > 0 & !loading ?
          <>
            <RenderRequests />
          </>
        :
          <div>
            <PageIcon style={{fontSize: '6em', marginInline: 'auto', marginBlockStart: '32vh', color: 'gray'}} />
            <div style={{marginTop: 12}}>{!loading ? 'У вас пока нет заявок.' : ''}</div>
          </div>
        }
      </div>
    </div>
    </>
  );
}

export default Requests;