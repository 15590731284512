import { Swiper, SwiperSlide} from 'swiper/react';
import { FreeMode} from 'swiper/modules';
import { IconButton, Text, Button } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { useState} from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css'
import * as AngleRight from '@fortawesome/free-solid-svg-icons/faAngleRight';


import RetakeSheet from '../BottomSheets/RetakeSheet';
import SearchSheet from '../BottomSheets/SearchSheet';

const BackButton = window.Telegram.WebApp.BackButton;
const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
      <path d={svgPathData}></path>
    </svg>
  );
};


const MainSwipper = (props) => {

  const [searchOpen, setSearchOpen] = useState(false);
  const [retakeOpen, setRetakeOpen] = useState(false);
  const [product, setProduct] = useState({});
  const [productImage, setProductImage] = useState('');
  const imageIdMapper = props.imageIdMapper;
  const retakeBooks = props.books;
  const title = props.title;
  
  
  function handleBack(){
    console.log(2)
    setSearchOpen(false);
    setRetakeOpen(false);
    BackButton.isVisible = false;
  }

  const handleOpen = () => {
    BackButton.onClick(handleBack);
    BackButton.isVisible = true;
    setSearchOpen(true);
    
  };

  const handleRetakeOpen = (item, product_image) => {
    BackButton.onClick(handleBack);
    setProduct(item);
    setProductImage(product_image);
    setRetakeOpen(true);
    // setSearchOpen(false);
    BackButton.isVisible = true;
    
  };


  return (
    <>
    <div style={{marginLeft: 12}}>
    <div className='header'>
        <div style={{width: '90%'}}>{title}</div>
        <div>
          <IconButton
              circle
              icon={<Icon as={FaSvgIcon} faIcon={AngleRight} style={{width: '1.0em', height: '1.0em'}}/>}
              appearance='subtle'
              onClick={handleOpen}
            />
        </div>
    </div>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={2}
        className="main-mySwiper"
        freeMode={true}
        modules={[FreeMode]}
        >
          {retakeBooks.map((item, index) => (
            <SwiperSlide 
              key={index}
              onClick={()=>handleRetakeOpen(item, imageIdMapper[item.img])}
              >
                <div style={{textAlign: 'center'}} key={index}>
                  <div className='slide-product-container'>
                    <img style={{width: item.w, height: item.h}} alt={item.book} src={imageIdMapper[item.img]}/>
                  </div>
                  <div style={{textAlign: 'left', marginTop: 4, marginLeft: 12, width: 140}}>
                    <Text maxLines={1} size='md'>{item.book}</Text>
                    <Text maxLines={1} size='sm' style={{color: 'gray'}}>{item.type}</Text>
                    <div>
                      <Button size='sm' style={{borderRadius: 60, height: 20}}>
                        {item.discount === 0 ?
                          <span>{item.price}₽</span>
                        : <>
                            <s style={{color: 'gray'}}>{item.price}</s>
                            <span style={{color: '#fc6a6a', marginLeft: 4}}>{item.price*(1-item.discount*0.01)}₽</span>
                          </>
                        }
                      </Button>
                    </div>
                  </div>
                </div>
            
            </SwiperSlide>
          ))}
          
    
      </Swiper>
    </div>

    <RetakeSheet open={retakeOpen} setOpen={setRetakeOpen} product={product} image={productImage} setBalance={props.setBalance} balance={props.balance} searchOpen={searchOpen}/>
    <SearchSheet open={searchOpen} setOpen={setSearchOpen} array={retakeBooks} imageIdMapper={imageIdMapper} handleRetakeOpen={handleRetakeOpen} />
    </>
  );
}

export default MainSwipper;