// Английский
export const imgRetake = {
  1: require('../images/evolve1.jpg'),
  2: require('../images/evolve2.jpg'),
  3: require('../images/evolve3.jpg'),
  4: require('../images/evolve4.jpg'),
  5: require('../images/evolve5.jpg'),
  6: require('../images/evolve5.jpg'),
  7: require('../images/evolve5.jpg'),
  8: require('../images/evolve6.jpg'),
  9: require('../images/evolve6.jpg'),
  10: require('../images/evolve6.jpg'),
  11: require('../images/viewpoint2.jpg'),
  12: require('../images/viewpoint2.jpg'),
  13: require('../images/stepup.jpg'),
  14: require('../images/objective_inter.jpg'),
  15: require('../images/objective_advanced.jpg'),
  16: require('../images/complete4_5.jpg'),
  17: require('../images/complete4_5.jpg'),
  18: require('../images/complete6_7.jpg'),
  19: require('../images/complete6_7.jpg'),
  20: require('../images/cae1.jpg'),
  21: require('../images/cae2.jpg'),
};
export const retakeBooks = [
  {'book': 'Evolve 1','discount': 12.5, 'type': 'Пересдача', 'price': 4000, 'w': 90, 'h': 132, 'img': 1},
  {'book': 'Evolve 2','discount': 12.5, 'type': 'Пересдача', 'price': 4000, 'w': 90, 'h': 132, 'img': 2},
  {'book': 'Evolve 3','discount': 0, 'type': 'Пересдача', 'price': 4000, 'w': 90, 'h': 132, 'img': 3},
  {'book': 'Evolve 4','discount': 0, 'type': 'Пересдача', 'price': 4500, 'w': 90, 'h': 132, 'img': 4},
  {'book': 'Evolve 5','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 5},
  {'book': 'Evolve 5.1','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 6},
  {'book': 'Evolve 5.2','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 7},
  {'book': 'Evolve 6','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 8},
  {'book': 'Evolve 6.1','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 9},
  {'book': 'Evolve 6.2','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 10},
  {'book': 'ViewPoint 2.1','discount': 0, 'type': 'Пересдача', 'price': 6500, 'w': 90, 'h': 132, 'img': 11},
  {'book': 'ViewPoint 2.2','discount': 0, 'type': 'Пересдача', 'price': 6500, 'w': 90, 'h': 132, 'img': 12},
  {'book': 'Step Up','discount': 0, 'type': 'Пересдача', 'price': 7000, 'w': 90, 'h': 132, 'img': 13},
  {'book': 'Objective Intermediate','discount': 0, 'type': 'Пересдача', 'price': 7500, 'w': 90, 'h': 132, 'img': 14},
  {'book': 'Objective Advanced','discount': 0, 'type': 'Пересдача', 'price': 7500, 'w': 90, 'h': 132, 'img': 15},
  {'book': 'Complete 4','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 16},
  {'book': 'Complete 5','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 17},
  {'book': 'Complete 6.5','discount': 0, 'type': 'Пересдача', 'price': 8500, 'w': 90, 'h': 132, 'img': 18},
  {'book': 'Complete 7.5','discount': 0, 'type': 'Пересдача', 'price': 8500, 'w': 90, 'h': 132, 'img': 19},
  {'book': 'CAE 1','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 20},
  {'book': 'CAE 2','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 21},

];
// Математика
export const imgMath = {
  1: require('../images/evolve1.jpg'),
  2: require('../images/evolve2.jpg'),
  3: require('../images/evolve3.jpg'),
  4: require('../images/evolve4.jpg'),
  5: require('../images/evolve5.jpg'),
  6: require('../images/evolve5.jpg'),
  7: require('../images/evolve5.jpg'),
  8: require('../images/evolve6.jpg'),
  9: require('../images/evolve6.jpg'),
  10: require('../images/evolve6.jpg'),
  11: require('../images/viewpoint2.jpg'),
  12: require('../images/viewpoint2.jpg'),
  13: require('../images/stepup.jpg'),
  14: require('../images/objective_inter.jpg'),
  15: require('../images/objective_advanced.jpg'),
  16: require('../images/complete4_5.jpg'),
  17: require('../images/complete4_5.jpg'),
  18: require('../images/complete6_7.jpg'),
  19: require('../images/complete6_7.jpg'),
  20: require('../images/cae1.jpg'),
  21: require('../images/cae2.jpg'),
};
export const mathBooks = [
  {'book': 'Evolve 1','discount': 0, 'type': 'Пересдача', 'price': 3500, 'w': 90, 'h': 132, 'img': 1},
  {'book': 'Evolve 2','discount': 0, 'type': 'Пересдача', 'price': 3500, 'w': 90, 'h': 132, 'img': 2},
  {'book': 'Evolve 3','discount': 0, 'type': 'Пересдача', 'price': 3500, 'w': 90, 'h': 132, 'img': 3},
  {'book': 'Evolve 4','discount': 0, 'type': 'Пересдача', 'price': 4500, 'w': 90, 'h': 132, 'img': 4},
  {'book': 'Evolve 5','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 5},
  {'book': 'Evolve 5.1','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 6},
  {'book': 'Evolve 5.2','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 7},
  {'book': 'Evolve 6','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 8},
  {'book': 'Evolve 6.1','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 9},
  {'book': 'Evolve 6.2','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 10},
  {'book': 'ViewPoint 2.1','discount': 0, 'type': 'Пересдача', 'price': 6500, 'w': 90, 'h': 132, 'img': 11},
  {'book': 'ViewPoint 2.2','discount': 0, 'type': 'Пересдача', 'price': 6500, 'w': 90, 'h': 132, 'img': 12},
  {'book': 'Step Up','discount': 0, 'type': 'Пересдача', 'price': 7000, 'w': 90, 'h': 132, 'img': 13},
  {'book': 'Objective Intermediate','discount': 0, 'type': 'Пересдача', 'price': 7500, 'w': 90, 'h': 132, 'img': 14},
  {'book': 'Objective Advanced','discount': 0, 'type': 'Пересдача', 'price': 7500, 'w': 90, 'h': 132, 'img': 15},
  {'book': 'Complete 4','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 16},
  {'book': 'Complete 5','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 17},
  {'book': 'Complete 6.5','discount': 0, 'type': 'Пересдача', 'price': 8500, 'w': 90, 'h': 132, 'img': 18},
  {'book': 'Complete 7.5','discount': 0, 'type': 'Пересдача', 'price': 8500, 'w': 90, 'h': 132, 'img': 19},
  {'book': 'CAE 1','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 20},
  {'book': 'CAE 2','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 21},

];
// Физика
export const imgPhysics = {
  1: require('../images/evolve1.jpg'),
  2: require('../images/evolve2.jpg'),
  3: require('../images/evolve3.jpg'),
  4: require('../images/evolve4.jpg'),
  5: require('../images/evolve5.jpg'),
  6: require('../images/evolve5.jpg'),
  7: require('../images/evolve5.jpg'),
  8: require('../images/evolve6.jpg'),
  9: require('../images/evolve6.jpg'),
  10: require('../images/evolve6.jpg'),
  11: require('../images/viewpoint2.jpg'),
  12: require('../images/viewpoint2.jpg'),
  13: require('../images/stepup.jpg'),
  14: require('../images/objective_inter.jpg'),
  15: require('../images/objective_advanced.jpg'),
  16: require('../images/complete4_5.jpg'),
  17: require('../images/complete4_5.jpg'),
  18: require('../images/complete6_7.jpg'),
  19: require('../images/complete6_7.jpg'),
  20: require('../images/cae1.jpg'),
  21: require('../images/cae2.jpg'),
};
export const physicsBooks = [
  {'book': 'Evolve 1','discount': 0, 'type': 'Пересдача', 'price': 3500, 'w': 90, 'h': 132, 'img': 1},
  {'book': 'Evolve 2','discount': 0, 'type': 'Пересдача', 'price': 3500, 'w': 90, 'h': 132, 'img': 2},
  {'book': 'Evolve 3','discount': 0, 'type': 'Пересдача', 'price': 3500, 'w': 90, 'h': 132, 'img': 3},
  {'book': 'Evolve 4','discount': 0, 'type': 'Пересдача', 'price': 4500, 'w': 90, 'h': 132, 'img': 4},
  {'book': 'Evolve 5','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 5},
  {'book': 'Evolve 5.1','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 6},
  {'book': 'Evolve 5.2','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 7},
  {'book': 'Evolve 6','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 8},
  {'book': 'Evolve 6.1','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 9},
  {'book': 'Evolve 6.2','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 10},
  {'book': 'ViewPoint 2.1','discount': 0, 'type': 'Пересдача', 'price': 6500, 'w': 90, 'h': 132, 'img': 11},
  {'book': 'ViewPoint 2.2','discount': 0, 'type': 'Пересдача', 'price': 6500, 'w': 90, 'h': 132, 'img': 12},
  {'book': 'Step Up','discount': 0, 'type': 'Пересдача', 'price': 7000, 'w': 90, 'h': 132, 'img': 13},
  {'book': 'Objective Intermediate','discount': 0, 'type': 'Пересдача', 'price': 7500, 'w': 90, 'h': 132, 'img': 14},
  {'book': 'Objective Advanced','discount': 0, 'type': 'Пересдача', 'price': 7500, 'w': 90, 'h': 132, 'img': 15},
  {'book': 'Complete 4','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 16},
  {'book': 'Complete 5','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 17},
  {'book': 'Complete 6.5','discount': 0, 'type': 'Пересдача', 'price': 8500, 'w': 90, 'h': 132, 'img': 18},
  {'book': 'Complete 7.5','discount': 0, 'type': 'Пересдача', 'price': 8500, 'w': 90, 'h': 132, 'img': 19},
  {'book': 'CAE 1','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 20},
  {'book': 'CAE 2','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 21},

];
// Физика
export const imgBJD = {
  1: require('../images/dz.png'),
  2: require('../images/test.png'),
  3: require('../images/evolve3.jpg'),
  4: require('../images/evolve4.jpg'),
  5: require('../images/evolve5.jpg'),
  6: require('../images/evolve5.jpg'),
  7: require('../images/evolve5.jpg'),
  8: require('../images/evolve6.jpg'),
  9: require('../images/evolve6.jpg'),
  10: require('../images/evolve6.jpg'),
  11: require('../images/viewpoint2.jpg'),
  12: require('../images/viewpoint2.jpg'),
  13: require('../images/stepup.jpg'),
  14: require('../images/objective_inter.jpg'),
  15: require('../images/objective_advanced.jpg'),
  16: require('../images/complete4_5.jpg'),
  17: require('../images/complete4_5.jpg'),
  18: require('../images/complete6_7.jpg'),
  19: require('../images/complete6_7.jpg'),
  20: require('../images/cae1.jpg'),
  21: require('../images/cae2.jpg'),
};
export const BJDBooks = [
  {'book': 'ДЗ Пупыркин А.В.','discount': 0, 'type': 'Вариант №2 2023г.', 'price': 3500, 'w': 120, 'h': 120, 'img': 1},
  {'book': 'Тест Пупкин А.В.','discount': 0, 'type': 'Вариант №2 2023г.', 'price': 3500, 'w': 120, 'h': 120, 'img': 2},
  {'book': 'Evolve 3','discount': 0, 'type': 'Пересдача', 'price': 3500, 'w': 90, 'h': 132, 'img': 3},
  {'book': 'Evolve 4','discount': 0, 'type': 'Пересдача', 'price': 4500, 'w': 90, 'h': 132, 'img': 4},
  {'book': 'Evolve 5','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 5},
  {'book': 'Evolve 5.1','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 6},
  {'book': 'Evolve 5.2','discount': 0, 'type': 'Пересдача', 'price': 5500, 'w': 90, 'h': 132, 'img': 7},
  {'book': 'Evolve 6','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 8},
  {'book': 'Evolve 6.1','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 9},
  {'book': 'Evolve 6.2','discount': 0, 'type': 'Пересдача', 'price': 6000, 'w': 90, 'h': 132, 'img': 10},
  {'book': 'ViewPoint 2.1','discount': 0, 'type': 'Пересдача', 'price': 6500, 'w': 90, 'h': 132, 'img': 11},
  {'book': 'ViewPoint 2.2','discount': 0, 'type': 'Пересдача', 'price': 6500, 'w': 90, 'h': 132, 'img': 12},
  {'book': 'Step Up','discount': 0, 'type': 'Пересдача', 'price': 7000, 'w': 90, 'h': 132, 'img': 13},
  {'book': 'Objective Intermediate','discount': 0, 'type': 'Пересдача', 'price': 7500, 'w': 90, 'h': 132, 'img': 14},
  {'book': 'Objective Advanced','discount': 0, 'type': 'Пересдача', 'price': 7500, 'w': 90, 'h': 132, 'img': 15},
  {'book': 'Complete 4','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 16},
  {'book': 'Complete 5','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 17},
  {'book': 'Complete 6.5','discount': 0, 'type': 'Пересдача', 'price': 8500, 'w': 90, 'h': 132, 'img': 18},
  {'book': 'Complete 7.5','discount': 0, 'type': 'Пересдача', 'price': 8500, 'w': 90, 'h': 132, 'img': 19},
  {'book': 'CAE 1','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 20},
  {'book': 'CAE 2','discount': 0, 'type': 'Пересдача', 'price': 8000, 'w': 90, 'h': 132, 'img': 21},

];
