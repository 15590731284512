import { Button, Text, Input, InputGroup } from "rsuite";
import { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import SearchIcon from '@rsuite/icons/Search';

const BackButton = window.Telegram.WebApp.BackButton;


const SearchSheet = ({open, setOpen, imageIdMapper, array, handleRetakeOpen}) => {
  const [search, setSearch] = useState('');

  function onDismiss(){
    BackButton.isVisible = false;
    setOpen(false);
  }

  const filtered_books = array.filter((v, i) =>{
    return(v.book.toLowerCase().includes(search.toLowerCase()))
  });

  return (
    <BottomSheet open={open} onDismiss={onDismiss} blocking={false} expandOnContentDrag
      defaultSnap={({ maxHeight }) => maxHeight}
      snapPoints={({ maxHeight }) => [
        maxHeight,
      ]}
      header={
        <div style={{marginTop: 8, display: 'flex', justifyContent: 'center', background: 'var(--secondary-bg-color)', height: 60, alignItems: 'center', borderRadius: '0px 0px 20px 20px'}}>
          <InputGroup style={{width: '90%', height: '60%'}}>
            <Input  placeholder="Поиск" onChange={(e) => setSearch(e)}/>
            <InputGroup.Addon><SearchIcon style={{background: 'transparent'}}/></InputGroup.Addon>
          </InputGroup>
        </div>
      }
      >
      <div style={{background: 'var(--primary-bg-color)'}}>
        <div style={{display: 'grid', gridTemplateColumns: 'auto auto', justifyContent: 'center', marginTop: 64}}>
              {filtered_books.map((item, index) => (
                <div key={index} className='drawer-slide' onClick={()=>handleRetakeOpen(item, imageIdMapper[item.img])}>
                  <div key={index}>
                    <div style={{textAlign: 'center'}}>
                      <div className='slide-product-container'>
                        <img style={{width: 90, height: 132}} alt={item.book} src={imageIdMapper[item.img]}/>
                      </div>
                      <div style={{textAlign: 'left', marginTop: 4, marginLeft: 12, width: 140}}>
                        <Text maxLines={1} size='md'>{item.book}</Text>
                        <Text maxLines={1} size='sm' style={{color: 'gray'}}>{item.type}</Text>
                        <div style={{}}>
                          <Button size='sm' style={{borderRadius: 60, height: 20}}>
                            {item.discount === 0 ?
                              <span>{item.price}₽</span>
                            : <>
                                <s style={{color: 'gray'}}>{item.price}</s>
                                <span style={{color: '#fc6a6a', marginLeft: 4}}>{item.price*(1-item.discount*0.01)}₽</span>
                              </>
                            }
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </BottomSheet>
  );
}

export default SearchSheet;