import { useState } from "react";
import axios from "axios";


function useAuth() {

  function checkUser() {
    const user = window.Telegram.WebApp.initDataUnsafe?.user?.id
    return user && user
  };
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(checkUser());
  const [number, setNumber] = useState(false);

  function authUser(){
    if(user){
      axios({
        method: "POST",
        url:"https://3166783-sf57923.twc1.net/api/auth/",
        headers:{
          'Accept': 'application/json'
        },
        data: {'user_id': window.Telegram.WebApp.initDataUnsafe?.user?.id, 'user_name': window.Telegram.WebApp.initDataUnsafe?.user?.first_name.toString()}
      })
      .then((response) => {
        const data = response.data
        if(data.contact !== '' && data.contact !== undefined && data.contact !== null){setNumber(true)}
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response)
          }
      })
    };

  }
  authUser();


  return {
    user,
    number,
    loading
  }
};


export default useAuth;

