import { Button, Loader } from "rsuite";
import axios from "axios";

const handleButton = () => {
  axios({
    method: "POST",
    url:"https://3166783-sf57923.twc1.net/api/insert_num/",
    headers:{
      'Accept': 'application/json'
    },
    data: {'user_id': window.Telegram.WebApp.initDataUnsafe?.user?.id}
  })
  .then((response) => {
    window.Telegram.WebApp.close()
  })
  .catch((error) => {
    if (error.response) {
      console.log(error.response)
      }
  })
};



const Contact = (props) => {

  if(!props.loading){
    return (
      <>
      <div className='main_container' style={{height: '100%'}}>
        <div style={{fontSize: 32, marginTop: 60}}>Чтобы продолжить пользоваться ботом, тебе нужно поделиться своим контактом</div>
        <Button appearance='primary' color='blue' style={{marginTop: 32}} onClick={()=>handleButton()}>Поделиться контактом</Button>
      </div>
      </>
    );
  }else{
    return(
      <Loader size='lg' center/>
    );
  }
}

export default Contact;