import axios from "axios";
import { useEffect, useState } from "react";
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import TimeIcon from '@rsuite/icons/Time';
import PageIcon from '@rsuite/icons/Page';
import { Loader, Button, ButtonGroup} from "rsuite";
import { Icon } from '@rsuite/icons';
import * as Star from '@fortawesome/free-solid-svg-icons/faStar';

const BackButton = window.Telegram.WebApp.BackButton;
const tg = window.Telegram.WebApp;

const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
      <path d={svgPathData}></path>
    </svg>
  );
};
const GetIcon = (props) => {
  let status = props.status;
  if(status === 'На рассмотрении'){
    return(<TimeIcon style={{fontSize: '2em'}}/>)
  }else if(status === 'Куплен' || status === 'Выполнена'){
    return(<CheckOutlineIcon style={{fontSize: '2em', color: '#2af318'}}/>)
  }else if(status === 'Выполняется'){
    return(<TimeIcon style={{fontSize: '2em', color: 'yellow'}}/>)
  }else{
    return(<CloseOutlineIcon style={{fontSize: '2em', color: 'red'}}/>)
  }
}
const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key].slice(0, 10)]: [
      ...(result[item[key].slice(0, 10)] || []),
      item,
    ],
  }), 
  {},
);
const getRate = (status) => {
  if(status === 'Выполнена'){
    return false;
  }else{
    return true;
  }
};

Object.entries = function(obj) {
  return Object.keys(obj).reduce(function(arr, key) {
      arr.push([key, obj[key]]);
      return arr;
  }, []);
}

const Purchases = () => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);

  function getRequest(){
    axios({
      method: "POST",
      url:"https://3166783-sf57923.twc1.net/api/get_purchases/",
      headers:{
        'Accept': 'application/json'
      },
      data: {'user_id': tg.initDataUnsafe?.user?.id, 'count': 50}
    })
    .then((response) => {
      const data = response.data
      setRequests(data.purchases);
      setLoading(false);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response)
        }
    })
  
  };
  
  useEffect(()=>{
    getRequest();
  }, []);

  BackButton.isVisible = true;
  BackButton.onClick(() => window.history.go(-1))


  function getStringDate(date) {
    const [day, month, year] = date.split('-')
    let this_date = new Date(year, month-1, day)
    let today = new Date().toLocaleDateString()
    let yesterday = new Date(new Date().setDate(new Date().getDate()-1)).toLocaleDateString()

    if(today === `${day}.${month}.${year}`){
      return 'Сегодня'
    }else if(yesterday === `${day}.${month}.${year}`){
      return 'Вчера'
    }else{
      let str_date = this_date.toLocaleDateString('ru-RU', {weekday: 'short', day: 'numeric', month: 'long'})
      return str_date[0].toUpperCase() + str_date.slice(1)
    }
  }


  const RenderRequests = () => {
    const result = groupBy(requests, 'date')
    const reqs = result

    return (
      <div>
        {Object.entries(reqs).map((item, index) => (
          <div key={index} style={{padding: 12}}>
            <div key={index} style={{fontSize: 20, textAlign: 'left', color: 'gray'}}>{getStringDate(item[0])}</div>
              <div style={{marginTop: 8}}>

                {item[1].map((i, ind) => (
                  <div key={ind} style={{background: 'var(--secondary-bg-color)', marginTop: 8, borderRadius: 20, textAlign: 'left', height: 164, padding: 12}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div style={{color: 'gray'}}>
                        <div style={{fontSize: 16, color: 'white'}}>{i.product} {i.type} </div>
                        <div style={{fontSize: 12}}> Стоимость: {i.cost}₽ </div>
                        <div style={{fontSize: 12}}> Время покупки: {i.date.slice(10)}</div>
                        <div style={{fontSize: 12}}> Статус: {i.status}</div>
                      </div>

                      <div style={{float: 'right', textAlign: 'right'}}>
                        <GetIcon status={i.status} />
                      </div>
                    </div>
                  <div style={{marginTop: 12, marginBottom: 12, height: 1, border: '0.5px solid #3b3b3b'}}></div>
                  <ButtonGroup justified>
                    {i.type !== 'Пересдача' ? <Button appearance="primary" color='green'>Скачать файл</Button> : <Button appearance="primary" color='blue' onClick={()=>window.location.replace('https://t.me/unknownanx')}>Поддержка</Button>}
                    <Button appearance="default" disabled={!(i.status === 'Выполнена'|| i.status === 'Куплен')} endIcon={<Icon as={FaSvgIcon} faIcon={Star} style={{width: '1.5em', height: '1.5em', color: 'orange'}}/>}>Оценить</Button>
                  </ButtonGroup>
                  </div>
                ))}
              </div>
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
    <div className='main_container' style={{height: '100%'}}>
      <div className='header'>
        Мои покупки
      </div>
      <div>
        
        {
          loading ?
          <div>
            <Loader size='lg' center vertical backdrop/>
          </div> :
          <></>
        }
        {requests.length > 0 & !loading ?
          <>
            <RenderRequests />
          </>
        :
          <div>
            <PageIcon style={{fontSize: '6em', marginInline: 'auto', marginBlockStart: '32vh', color: 'gray'}} />
            <div style={{marginTop: 12}}>{!loading ? 'У вас пока нет покупок.' : ''}</div>
          </div>
        }
      </div>
    </div>
    </>
  );
}

export default Purchases;