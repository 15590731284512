import { BottomSheet } from 'react-spring-bottom-sheet'
import { Form, DatePicker, Input, Button, Schema, Toggle} from 'rsuite'
import { Icon } from '@rsuite/icons';
import { useState, forwardRef, useRef } from 'react'
import axios from 'axios';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';



const Textarea = forwardRef((props, ref) => <Input maxLength={150} style={{resize: 'none', height: 120}} {...props} as="textarea" ref={ref} />);

const { DateType, StringType } = Schema.Types;
const model = Schema.Model({
  date: DateType().isRequired('Это обязательное поле.'),
  inst: StringType().isRequired('Это обязательное поле.'),
});

const tg = window.Telegram.WebApp;

const initFormValue = {
  'date': null,
  'inst': '',
  'write_part': false,
  'comment': '',
}

const BackButton = window.Telegram.WebApp.BackButton;


const RetakeSheet = ({open, setOpen, product, image, balance, setBalance, searchOpen}) => {
  const name = product.book
  const price = product.price
  const discount = product.discount
  const [writePart, setWritePart] = useState(0);
  const [formValue, setFormValue] = useState(initFormValue);
  const [blocked, setBlocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('request')
  const [finishText, setFinishText] = useState('Успешная покупка')
  const formRef = useRef();

  function onDismiss(){
    setOpen(false);
    if(!searchOpen){
      BackButton.isVisible = false;
    }
  };
  function onExit(){
    setOpen(false);
    BackButton.isVisible = false;
    window.location.replace('/')
    window.location.reload()
  };
  function CreateRetake(){
    setLoading(true);
    setBlocked(true);
    axios({
      method: "POST",
      url:"https://3166783-sf57923.twc1.net/api/create_retake/",
      headers:{
        'Accept': 'application/json'
      },
      data: {
        'user_id': tg.initDataUnsafe?.user?.id,
        'price': price*(1-discount*0.01)+writePart,
        'user_tag': tg.initDataUnsafe?.user?.username,
        'product': product,
        'date': formValue.date,
        'inst': formValue.inst,
        'comment': formValue.comment,
        'type': 'Пересдача',
        'write_part': writePart

      }
    })
    .then((response) => {
      const data = response.data
      setStatus('success')
      setBalance(balance-(price*(1-discount*0.01)+writePart))
    })
    .catch((error) => {
      if (error.response) {
        const error_text = error.response.data.error
        setStatus('error')
        setFinishText(error_text)
        // console.log(error_text)
        }
    })
  };
  const handleSubmit = () => { 
    if (!formRef.current.check()) {
      console.error('Form Error');
      return;
    }

    const PopupCallback = (button_id) => {
      if (button_id === '0'){CreateRetake()}
    };

    window.Telegram.WebApp.showPopup({
      title: 'Подтвердите покупку',
      message: `Подтвердите покупку на сумму ${price*(1-discount*0.01)+writePart}₽`,
      buttons: [
        {id: 0, type: 'ok'},
        {id: 1, type: 'destructive', text: 'Отмена'}
      ]
    }, PopupCallback)

  };
    
  
  if(status === 'request'){
    return (
    <>
      <BottomSheet open={open} onDismiss={onDismiss} blocking={false} expandOnContentDrag
        defaultSnap={({ maxHeight }) => maxHeight}
        snapPoints={({ maxHeight }) => [
          maxHeight
        ]}
        footer={
          <>
            <Button appearance='primary' color='green' block type='submit' disabled={blocked} loading={loading}
                    onClick={handleSubmit}>Купить ({price*(1-discount*0.01)+writePart}₽)
            </Button>
            <div style={{color: 'gray', fontSize: 12, textAlign: 'center', marginTop: 8}}>
              После нажатия на кнопку с вашего баланса будет списано {price*(1-discount*0.01)+writePart}₽
            </div>
          </>

        }
        >
        <div style={{padding: 12, background: 'var(--primary-bg-color)'}}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{background: 'var(--secondary-bg-color)', width: '100vw', height: '100vw', borderRadius: 20, padding: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 4}}>
              <img style={{width: '64vw', height: '95vw'}} alt={name} src={image}/>
            </div>
          </div>
          <div style={{marginTop: 12}}>
            <div style={{fontSize: 20}}>{name}</div>
            <div style={{fontSize: 16, marginTop: 8}}>
              Стоимость услуги
              <span style={{marginLeft: 8, background: '#0a81c2', padding: 4, borderRadius: 12}}>
              {discount === 0 ?
                      <span>{price}₽</span>
                    : <>
                        <s style={{color: '#c0c0c0'}}>{price}</s>
                        <span style={{marginLeft: 4}}>{price*(1-discount*0.01)+writePart}₽</span>
                      </>
              }
              </span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{marginTop: 12, background: 'var(--secondary-bg-color)', padding: 12, borderRadius: 12, width: '100%'}}>
                <div style={{marginBottom: 24, fontSize: 20}}>Форма заявки</div>
                <Form formValue={formValue} onChange={formValue => setFormValue(formValue)} fluid model={model} ref={formRef}
                  >
                  <Form.Group controlId={'date'}>
                    <Form.ControlLabel>Дата пересдачи</Form.ControlLabel>
                    <Form.Control name='date' accepter={DatePicker}
                      oneTap
                      format='dd-MM-yyyy'
                      placeholder='Выберете дату пересдачи'
                      block={true}
                      size='lg'
                      editable={false}
                      />
                  </Form.Group>

                  <Form.Group controlId={'inst'}>
                    <Form.ControlLabel>Институт</Form.ControlLabel>
                    <Form.Control name='inst' accepter={Input} placeholder='Введите название института' size='lg' maxLength={20} tabIndex={0}/>
                  </Form.Group>

                  {product.img > 12 ?
                  <>
                    <Form.Group controlId={'write_part'}>
                      <Form.ControlLabel>Письменная часть(+2000₽)</Form.ControlLabel>
                      <Form.Control name='write_part' accepter={Toggle} size='lg' maxLength={50} placeholder='Введите доп. контакт' tabIndex={1}
                        checkedChildren='Выполните за меня'
                        unCheckedChildren='Выполните за меня'
                        onChange={e => e === true ? setWritePart(2000)  : setWritePart(0)}
                        />
                      <Form.HelpText>
                      Выбирая "Сделаю сам", вы соглашаетесь отправить письменную работу не позднее, чем за 2 дня до пересдачи. Если работа не соответствует требованиям, возвращается половина суммы. Рекомендуем прислать материал заранее для проверки и корректировки. Заявки, где мы выполняем письменную часть, обрабатываются в приоритетном порядке.
                      </Form.HelpText>
                    </Form.Group>
                  </>
                  : null}

                  <Form.Group controlId={'comment'}>
                    <Form.ControlLabel tabIndex={2}>Комментарий</Form.ControlLabel>
                    <Form.Control name='comment' accepter={Textarea} placeholder='Напишите ваши пожелания. Например: Отпишите по результатам'/>
                  </Form.Group>

                </Form>
              </div>
            </div>
          </div>
        </div>
      </BottomSheet>
    </>
  );}
  else if(status === 'success'){
    return(
      <BottomSheet open={open} onDismiss={onExit} blocking={false}
        defaultSnap={({ maxHeight }) => maxHeight}
        snapPoints={({ maxHeight }) => [
          maxHeight,
        ]}>
        <div style={{background: 'var(--primary-bg-color)', fontSize: 32, textAlign: 'center'}}>
          <div style={{marginTop: 24}}>
            <CheckOutlineIcon style={{fontSize: '5em', color: '#09fc29'}}/>
          </div>
          <div style={{marginTop: 48}}>
            {finishText}
          </div>
          <div style={{fontSize: 28}}>
            Не забудь зарегестрироваться на пересдачу в Touchstone *
          </div>
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <div style={{marginTop: 24, width: '90%'}}>
              <Button appearance='primary' block color='green' onClick={() => window.location.replace('https://vk.com/touchstonevmisis')}>Зарегистрироваться</Button>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <div style={{marginTop: 24, width: '90%'}}>
                <Button appearance='default' block onClick={onExit}>На главную</Button>
            </div>
          </div>
        </div>
      </BottomSheet>
  );}
  else{
    return(
      <BottomSheet open={open} onDismiss={onExit} blocking={false}
        defaultSnap={({ maxHeight }) => maxHeight}
        snapPoints={({ maxHeight }) => [
          maxHeight,
        ]}>
        <div style={{background: 'var(--primary-bg-color)', fontSize: 32, textAlign: 'center'}}>
          <div style={{marginTop: 24}}>
            <CloseOutlineIcon style={{fontSize: '5em', color: '#fc0909'}}/>
          </div>
          <div style={{marginTop: 48}}>
            {finishText}
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{marginTop: 24, width: '90%'}}>
              <Button block appearance='primary' color='blue' onClick={onExit}>На главную</Button>
            </div>
          </div>
        </div>
      </BottomSheet>
  );}
}

export default RetakeSheet;