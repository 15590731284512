import React from "react";

const SBPIcon = React.forwardRef((props, ref) => (
<svg width="60" height="30" viewBox="0 0 100 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 10.8838L6.05499 21.7067V28.3084L0.00708333 39.11L0 10.8838Z" fill="#5B57A2"/>
  <path d="M23.2485 17.7676L28.9223 14.2901L40.5339 14.2793L23.2485 24.8685V17.7676Z" fill="#D90751"/>
  <path d="M23.2168 10.8196L23.2488 25.1487L17.1797 21.4196V0L23.2172 10.8196H23.2168Z" fill="#FAB718"/>
  <path d="M40.5342 14.2796L28.9222 14.2904L23.2168 10.8196L17.1797 0L40.5338 14.2796H40.5342Z" fill="#ED6F26"/>
  <path d="M23.2488 39.17V32.2179L17.1797 28.5596L17.183 50.0004L23.2488 39.17Z" fill="#63B22F"/>
  <path d="M28.9083 35.7242L6.05458 21.7067L0 10.8838L40.5095 35.71L28.9079 35.7242H28.9083Z" fill="#1487C9"/>
  <path d="M17.1836 50.0004L23.2486 39.17L28.9082 35.7241L40.5094 35.71L17.1836 50.0004Z" fill="#017F36"/>
  <path d="M0.00732422 39.1093L17.2294 28.5593L11.4394 25.0068L6.05523 28.3077L0.00732422 39.1093Z" fill="#984995"/>
</svg>));

export default SBPIcon;