import './style.css'
import { Button, IconButton, Grid, Row, Col } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { useNavigate } from 'react-router-dom';
import * as Delete from '@fortawesome/free-solid-svg-icons/faDeleteLeft';
const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
      <path d={svgPathData}></path>
    </svg>
  );
};

const Keyboard = ({value, setValue, transferType, bank}) => {
  const navigate = useNavigate();
  let position = 0;

  function handleClick(v){
    switch (v){
      case 'delete':
        value.length > 1 ? setValue(value.substr(0, position -1) + value.substr(position, value.length)): setValue('0')
        break;

      default:
        if (value.length < 6){
          value === '0' ? setValue(v): setValue(value + v)
        } 
    }
  };

  function handleInput(v){
    if (v[0] === '0'){
      setValue(v.slice(1, v.length));
    }
    if (/^\d+$/.test(v) && v.length <= 6 && !v.includes(',')){
      setValue(v);
    }
  };
  
  const handleNext = () => {
    navigate('/top_up_balance/',
      {state:{
        type: transferType,
        amount: value,
        bank: bank,

      }}
    )
  };

  function handleBlur(v){
    position = v.target.selectionStart
    v.target.focus()
  }

  return (
    <>
    <div style={{width: '90%'}}>
      <div style={{height: 50, fontSize: 30, textAlign: 'left', marginLeft: 4}}>
        <input value={value} onInput={e => handleInput(e.target.value)} autoFocus={true} onBlur={e => handleBlur(e)} inputMode='none'
          style={{width: '200px', outline: 'none', background: 'transparent', border: '0px', boxShadow: 'none'}}
        />
      </div>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <Button block size='lg' onClick={handleNext} disabled={(value < 500 && transferType === 'СБП') || (value < 1000 && transferType === 'P2P')} >Пополнить</Button>
      </div>
      <Grid>
        <Row className="show-grid" style={{marginTop: 12}}>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('1')} appearance='subtle'>1</Button></Col>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('2')} appearance='subtle'>2</Button></Col>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('3')} appearance='subtle'>3</Button></Col>
        </Row>
        <Row className="show-grid" style={{marginTop: 12}}>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('4')} appearance='subtle'>4</Button></Col>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('5')} appearance='subtle'>5</Button></Col>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('6')} appearance='subtle'>6</Button></Col>
        </Row>
        <Row className="show-grid" style={{marginTop: 12}}>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('7')} appearance='subtle'>7</Button></Col>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('8')} appearance='subtle'>8</Button></Col>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('9')} appearance='subtle'>9</Button></Col>
        </Row>
        <Row className="show-grid" style={{marginTop: 12}}>
          <Col xs={8}><Button block style={{fontSize: 24}} appearance='link'> </Button></Col>
          <Col xs={8}><Button block style={{fontSize: 24}} onClick={() => handleClick('0')} appearance='subtle'>0</Button></Col>
          <Col xs={8}><IconButton block appearance='subtle' onClick={() => handleClick('delete')} icon={<Icon as={FaSvgIcon} style={{fontSize: '0.9em'}} faIcon={Delete} />}/></Col>
        </Row>
      </Grid>
    </div>
    </>
  );
}

export default Keyboard; 